import {IIdiomas} from "services-comun/modules/net/idiomas";

export default Object.freeze({
    idiomas: ['de', 'en', 'es', 'fr', 'it', 'nl', 'pt', 'pt-BR', 'ru'],
    defecto: "en",
    enabled: true,
} as IIdiomas);

export const soportados = ["en", "es", "fr", "de", "it", "pt"];
export const redirecciones: Record<string, string|undefined> = {
    "pt-PT": "pt",
};

interface ICheck {
    ok: boolean;
    idioma: string;
}

export const checkIdioma = (idioma: string): ICheck=>{
    return {
        // TS2550: Property 'includes' does not exist on type 'string[]'. Do you need to change your target library?
        // Try changing the 'lib' compiler option to 'es2016' or later.
        // ok: soportados.includes(idioma),
        ok: soportados.indexOf(idioma) !== -1,
        idioma: redirecciones[idioma] ?? idioma,
    };
};
